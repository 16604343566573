import { LoginPopUp } from "../popups/Login";

export function LoginButtonNavbar({ setDialog }) {
  return (
    <li>
      <button
        className="button"
        id="show-reg-modal-btn"
        onClick={() => setDialog(<LoginPopUp setDialog={setDialog} />)}
      >
        Авторизоваться
      </button>
    </li>
  );
}
