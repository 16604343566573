import { useRef, useState } from "react";
import { apiRoot, fetchApiPost } from "../../Requests";
import { showError, showOk } from "../../component-utils/NotificationModals";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { showMessage } from "../../messages";
export function ProfilePopUp({ setDialog, user, setSkinHeadTimestamp }) {
  const [skinTimestamp, setSkinTimestamp] = useState("?" + performance.now());

  const inputFile = useRef(null);

  const uploadImage = (w, h, route, then) => {
    inputFile.current.click();

    inputFile.current.onchange = () => {
      let file = inputFile.current.files[0];
      if (!file) {
        return;
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        let image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          if (image.width === w && image.height === h) {
            let base64 = e.target.result.substring(
              reader.result.indexOf("base64,") + "base64,".length
            );

            fetchApiPost(route, {
              basedImageFile: base64,
            }).then(([response, json]) => {
              if (response.ok) {
                then();
              } else {
                showError(json.message);
              }
            });
          } else {
            showError("Скин должен быть размером в 64х64 пикселя");
          }
        };
      };

      reader.readAsDataURL(file);
    };
  };

  const onSkinButtonClick = () => {
    uploadImage(64, 64, "upload_skin", () => {
      setSkinTimestamp("?" + performance.now());
      setSkinHeadTimestamp("?" + performance.now());

      showOk("Скин был успешно обновлён!");
    });
  };

  const onCapeButtonClick = () => {
    uploadImage(64, 32, "upload_cape", () => {
      showOk("Плащ был успешно обновлён!");
    });
  };

  let options = [{ value: null, label: "Плащ не выбран" }];
  user.capes.sort();
  for (let cape of user.capes) {
    options.push({ value: cape, label: cape });
  }

  let currentOption = options[0];
  if (user.currentCape !== null) {
    for (let i = 1; i <= options.length; i++) {
      if (options[i].value === user.currentCape) {
        currentOption = options[i];
        break;
      }
    }
  }

  let status = user.boughtDonate;
  if (status) {
    status = status.charAt(0).toUpperCase() + status.slice(1);
  }

  const [slimSkin, setSlimSkin] = useState(user.slimSkin);

  return (
    <dialog id="profile-dialog" onClose={() => setDialog(null)}>
      <div id="profile-modal-box">
        <div className="profile-left">
          <img
            src={
              apiRoot + "skins/preview/" + user.uuid + ".png" + skinTimestamp
            }
            alt="Фото профиля"
            draggable="false"
            className="skin-preview"
          />
          <button
            className="profile-btn modal-confirm-btn"
            onClick={onSkinButtonClick}
          >
            Смена скина
          </button>
          {user.capes.includes("custom") && (
            <button
              className="profile-btn modal-confirm-btn"
              onClick={onCapeButtonClick}
            >
              Смена плаща
            </button>
          )}
          <input
            type="file"
            accept=".png"
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>

        <div className="profile-right">
          <span className="profile-status">Статус - {status || "Игрок"}</span>
          <h1>{user && user.username}</h1>

          <div>
            <span className="about-profile">Об аккаунте</span>
            <div className="pass-status">
              <i className="fa-solid fa-ticket fa-2xl"></i>
              <span>
                {user.boughtPass ? "Проходка куплена" : "Проходка не куплена"}
              </span>
              {!user.boughtPass && ""}
            </div>

            <div id="slim-skin">
              <input
                type="checkbox"
                id="slim-skin-checkbox"
                checked={slimSkin}
                onChange={(e) => {
                  fetchApiPost("user/set_slim_skin?slim=" + !slimSkin).then(
                    ([response, json]) => {
                      if (response.ok) {
                        showOk("Скин был успешно обновлён!");
                      } else {
                        showMessage(json.message);
                      }
                    }
                  );

                  setSlimSkin(!slimSkin);
                }}
              ></input>
              <label for="slim-skin-checkbox" id="slim-skin-label">
                Alex-скин
              </label>
            </div>

            <Dropdown
              options={options}
              onChange={(e) => {
                if (currentOption.value !== e.value) {
                  currentOption = e;

                  let route =
                    e.value === null
                      ? "user/unselect_cape"
                      : "user/select_cape?cape=" + e.value;

                  fetchApiPost(route).then(([response, json]) => {
                    if (response.ok) {
                      showOk("Плащ был изменён!");
                    } else {
                      showMessage(json.message);
                    }
                  });
                }
              }}
              value={currentOption}
              placeholder="Плащ не выбран"
              className="capes-dropdown"
              controlClassName="capes-dropdown-control"
              placeholderClassName="capes-dropdown-placeholder"
            />

            <div
              className="unlogin-button-container"
              onClick={() => {
                window.location.reload();
                localStorage.clear("token");
              }}
            >
              <a className="unlogin-button">Выйти из профиля</a>
            </div>
          </div>
        </div>

        <button className="close-modal-btn" onClick={() => setDialog(null)}>
          <i className="fa-solid fa-xmark fa-2xl"></i>
        </button>
      </div>

      <div id="error-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="error-modal-box">
          <img src="images/ban.png" alt="ban" />
          <span className="error-text" id="error-text"></span>
        </div>
      </div>

      <div id="ok-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="ok-modal-box">
          <img src="images/ok.png" alt="ok" />
          <span className="ok-text" id="ok-text"></span>
        </div>
      </div>
    </dialog>
  );
}
