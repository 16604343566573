export const apiRoot = "https://api.kachanovcraft.com/";

export function fetchApiGet(route, navigateFunc) {
  let headers = {};

  let token = localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  }

  return fetch(apiRoot + route, {
    headers: headers,
  }).then(async (response) => {
    return [response, await response.json()];
  });
}

export function fetchApiPost(route, body = {}) {
  let headers = {
    "Content-Type": "application/json",
  };

  let token = localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  }

  return fetch(apiRoot + route, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  }).then(async (response) => {
    return [response, await response.json()];
  });
}
