import { useState } from "react";
import { apiRoot } from "../../Requests";
import { ProfilePopUp } from "../popups/Profile";

export function ProfileButtonNavbar({ setDialog, user }) {
  const [skinHeadTimestamp, setSkinHeadTimestamp] = useState("?" + performance.now());

  return (
    <li>
      <button
        className="button"
        id="show-profile-modal-btn"
        onClick={() =>
          setDialog(
            <ProfilePopUp
              setDialog={setDialog}
              user={user}
              setSkinHeadTimestamp={setSkinHeadTimestamp}
            />
          )
        }
      >
        {user && (
          <img
            className="head"
            src={apiRoot + "skins/preview/" + user.uuid + "_head.png" + skinHeadTimestamp}
            alt="Голова пользователя"
            draggable="false"
          />
        )}
        <span> {user && user.username}</span>
      </button>
    </li>
  );
}
