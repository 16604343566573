export function LinkButtonsNavbar() {
  return (
    <>
      <li>
        <a className="button" href="https://discord.com/invite/Wq6E5XC" target="_blank">
          <i className="icon fa-brands fa-discord fa-xl"></i>
        </a>
      </li>
      <li>
        <a className="button" href="https://www.youtube.com/@Kachanov" target="_blank">
          <i className="icon fa-brands fa-youtube fa-xl"></i>
        </a>
      </li>
      <li>
        <a className="button" href="https://t.me/kachanovhoi" target="_blank">
          <i className="icon fa-brands fa-telegram fa-xl"></i>
        </a>
      </li>
    </>
  );
}
