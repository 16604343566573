import { Navbar } from "./components/navbar/Navbar.jsx";
import { Banner } from "./components/Banner.jsx";
import { About } from "./components/About.jsx";
import { useEffect, useState } from "react";
import { fetchApiGet } from "./Requests.js";

export function Main() {
  const [user, setUser] = useState(undefined);
  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    fetchApiGet("user/self").then(([response, json]) => {
      if (response.status === 200) {
        setUser(json.response);
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (dialog) {
      document.getElementsByTagName("body")[0].classList.add("modal-open");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }

    for (let element of document.getElementsByTagName("dialog")) {
      if (element.id === "error-dialog") {
        element.style.zIndex = 100;
        continue;
      }

      element.style.zIndex = 10000;
      element.showModal();

      element.addEventListener("click", (e) => {
        let rect = element.getBoundingClientRect();
        let isInDialog =
          rect.top <= e.clientY &&
          e.clientY <= rect.top + rect.height &&
          rect.left <= e.clientX &&
          e.clientX <= rect.left + rect.width;
        if (!isInDialog && e.target.tagName !== "INPUT") {
          element.close();
        }
      });
    }
  }, [dialog]);

  return (
    <>
      <header>
        <Navbar user={user} setDialog={setDialog} />
      </header>
      <main>
        <Banner />
        <About />
        {/* <News /> */}

        {dialog && dialog}
      </main>
    </>
  );
}
