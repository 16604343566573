import { useState } from "react";
import { RegisterPopUp } from "./Register";
import { RecoverPasswordPopUp } from "./RecoverPassword";
import { fetchApiPost } from "../../Requests";
import { showMessage } from "../../messages";

export function LoginPopUp({ setDialog }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    if (username.length < 4) {
      showMessage("login.bad_username");
    } else if (password.length < 8 || password.length > 32) {
      showMessage("login.bad_password");
    } else {
      fetchApiPost("login", {
        username: username,
        password: password,
      }).then(([response, json]) => {
        if (response.ok) {
          localStorage.setItem("token", json.response.token);

          window.location.reload();
        } else {
          showMessage(json.message);
        }
      });
    }
  };

  return (
    <dialog id="login-dialog" onClose={() => setDialog(null)}>
      <div className="modal-box" id="login-modal-box">
        <div className="modal-header">
          <h2 id="reg">Войти</h2>
          <h2>KachanovCraft</h2>
        </div>
        <div className="modal-columns">
          <input
            type="text"
            placeholder="Никнейм или почта"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Пароль"
            maxLength="32"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="modal-btn modal-confirm-btn" onClick={login}>
            Войти
          </button>
          <div className="login-under-text">
            <span
              className="modal-under-text"
              id="forgot-pass"
              onClick={() => {
                setDialog(<RecoverPasswordPopUp setDialog={setDialog} />)
              }}
            >
              <button className="modal-under-text-link">Забыли пароль?</button>
            </span>
            <span className="modal-under-text" id="no-acc">
              Нет аккаунта?{" "}
              <button
                className="modal-under-text-link"
                id="show-reg-modal-btn2"
                onClick={() =>
                  setDialog(<RegisterPopUp setDialog={setDialog} />)
                }
              >
                Зарегистрируйтесь
              </button>
            </span>
          </div>
        </div>

        <button className="close-modal-btn" onClick={() => setDialog(null)}>
          <i className="fa-solid fa-xmark fa-2xl"></i>
        </button>
      </div>

      <div id="error-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="error-modal-box">
          <img src="images/ban.png" alt="ban" />
          <span className="error-text" id="error-text"></span>
        </div>
      </div>
    </dialog>
  );
}
