export function DownloadPopUp({ setDialog }) {
  return (
    <dialog id="download-dialog">
      <div className="modal-box" id="download-modal-box">
        <div className="modal-header">
          <h2 id="reg">Загрузить лаунчер</h2>
        </div>
        <div className="modal-columns">
          <a
            className="launcher-button"
            href="https://launcher.kachanovcraft.com/KachanovCraft.exe"
            target="_blank"
          >
            <img src="images/windows_symbol.svg.png" alt="Windows icon" />
            <div className="launcher-text">
              <h3 className="launcher-heading">Лаунчер для Windows (.exe)</h3>
              <span className="launcher-under-text">
                Версия лаунчера для самой популярной платформы
              </span>
            </div>
          </a>

          <a
            className="launcher-button"
            href="https://launcher.kachanovcraft.com/KachanovCraft.jar"
            target="_blank"
          >
            <img src="images/java_logo.svg.png" alt="Java icon" />
            <div className="launcher-text">
              <h3 className="launcher-heading">Лаунчер для любой ОС (.jar)</h3>
              <span className="launcher-under-text">
                Кроссплатформенная версия для Linux, MacOS и Windows
              </span>
            </div>
          </a>

          <a
            className="launcher-button"
            href="https://launcher.kachanovcraft.com/KachanovCraftWrapper.jar"
            target="_blank"
          >
            <img src="images/java_logo.svg.png" alt="Java icon" />
            <div className="launcher-text">
              <h3 className="launcher-heading">Лаунчер для любой ОС (.jar) (альтернатиная версия)</h3>
              <span className="launcher-under-text">
                Попробуйте эту версию, если предыдущий лаунчер не работает
              </span>
            </div>
          </a>
        </div>

        <button className="close-modal-btn" onClick={() => setDialog(null)}>
          <i className="fa-solid fa-xmark fa-2xl"></i>
        </button>
      </div>
    </dialog>
  );
}
