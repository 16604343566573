let navbarDesktop = null;
let navbarMobile = null;

let main = document.querySelector("main");

function load() {
  if (!navbarDesktop) {
    navbarDesktop = document.querySelector("#navbar-desktop");
  }

  if (!navbarMobile) {
    navbarMobile = document.querySelector("#navbar-mobile");

    for (let button of navbarMobile.querySelectorAll("button.button")) {
      button.addEventListener('click', (e) => {
        console.log(e);
        closeHamburger();
      });
    }
  }

  if (!main) {
    main = document.querySelector("main");
  }
}

export function openHamburger() {
  load();

  navbarDesktop.style.display = "none";
  navbarMobile.style.display = "block";

  main.style.display = "none";
}

export function closeHamburger() {
  load();

  navbarDesktop.style.display = "flex";
  navbarMobile.style.display = "none";

  main.style.display = "block";
}
