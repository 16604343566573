import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Main } from "./Main.jsx";
import { RegisterConfirm } from "./RegisterConfirm.jsx";
import { RecoverPasswordConfirm } from "./RecoverPasswordConfirm.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/register_confirm" element={<RegisterConfirm />} />
        <Route path="/recover_password_confirm" element={<RecoverPasswordConfirm />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
