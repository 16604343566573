import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchApiPost } from "./Requests";

export function RecoverPasswordConfirm() {
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }

    fetchApiPost("recover_password_confirm?token=" + token).then(
      ([response, json]) => {
        if (response.ok) {
          localStorage.setItem("token", json.response.token);
        }

        navigate("/");
      }
    );
  }, []);

  return <h1>Минуточку...</h1>;
}
