import { DownloadButtonNavbar } from "./DownloadButton.jsx";
import { ProfileButtonNavbar } from "./ProfileButton.jsx";
import { LinkButtonsNavbar } from "./LinkButtons.jsx";
import { LoginButtonNavbar } from "./LoginButton.jsx";
import { BuyPassButtonNavbar } from "./BuyPassButton.jsx";
import {
  closeHamburger,
  openHamburger,
} from "../../component-utils/Hamburger.js";

export function Navbar({ user, setDialog }) {
  return (
    <>
      {/* Navbar for Desktop devices */}
      <nav id="navbar-desktop">
        {/* Left from Logo Section */}
        <ul className="navbar-section" id="navbar-left">
          {user && !user.boughtPass && (
            <BuyPassButtonNavbar />
          )}
          {user && user.boughtPass && (
            <DownloadButtonNavbar setDialog={setDialog} />
          )}

          {!user && <LoginButtonNavbar setDialog={setDialog} />}
          {user && (
            <ProfileButtonNavbar setDialog={setDialog} user={user} />
          )}
        </ul>

        {/* Logo Section */}
        <ul id="navbar-center">
          <li>
            <a href="/">
              <h2>KachanovCraft</h2>
            </a>
          </li>
        </ul>

        {/* Right from Logo Section */}
        <ul className="navbar-section" id="navbar-right">
          <LinkButtonsNavbar />
        </ul>

        {/* Hamburger */}
        <div className="hamburger">
          <span onClick={openHamburger}>
            <i className="fa-solid fa-bars fa-xl"></i>
          </span>
        </div>
      </nav>

      {/* Navbar for Mobile devices*/}
      <nav id="navbar-mobile">
        {/* Logo Section */}
        <ul id="mobile-logo">
          <li>
            <a href="/">
              <h2>KachanovCraft</h2>
            </a>
          </li>

          <li>
            <div className="hamburger">
              <span onClick={closeHamburger}>
                <i className="fa-solid fa-xmark fa-xl"></i>
              </span>
            </div>
          </li>
        </ul>

        {/* Buttons section */}
        <ul id="navbar-mobile-items">
          {user && !user.boughtPass && (
            <BuyPassButtonNavbar />
          )}
          {user && user.boughtPass && (
            <DownloadButtonNavbar setDialog={setDialog} />
          )}

          {user === null && <LoginButtonNavbar setDialog={setDialog} />}
          {user !== null && user !== undefined && (
            <ProfileButtonNavbar setDialog={setDialog} user={user} />
          )}

          <LinkButtonsNavbar />
        </ul>
      </nav>
    </>
  );
}
