import { useState } from "react";
import { LoginPopUp } from "./Login";
import { fetchApiPost } from "../../Requests";
import { showMessage } from "../../messages";

export function RegisterPopUp({ setDialog }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const register = () => {
    if (username.length < 4 || username.length > 16) {
      showMessage("register.bad_username");
    } else if (email.length < 4 || !email.includes('@') || !email.includes('.')) {
      showMessage("register.bad_email");
    } else if (password.length < 8 || password.length > 32) {
      showMessage("register.bad_password");
    } else {
      fetchApiPost("register", {
        username: username,
        email: email,
        password: password,
      }).then(([response, json]) => {
        showMessage(json.message);
      });
    }
  };

  return (
    <dialog id="reg-dialog" onClose={() => setDialog(null)}>
      <div className="modal-box" id="reg-modal-box">
        <div className="modal-header">
          <h2>Зарегистрироваться</h2>
          <h2>KachanovCraft</h2>
        </div>
        <div className="modal-columns">
          <input
            type="text"
            placeholder="Ник в Minecraft"
            maxLength="16"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="text"
            placeholder="Почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Пароль"
            maxLength="32"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="modal-btn modal-confirm-btn" onClick={register}>
            Зарегистрироваться
          </button>
          <span className="modal-under-text">
            Уже есть аккаунт?{" "}
            <button
              className="modal-under-text-link"
              id="show-login-modal-btn"
              onClick={() => setDialog(<LoginPopUp setDialog={setDialog} />)}
            >
              Войдите
            </button>
          </span>
        </div>

        <button className="close-modal-btn" onClick={() => setDialog(null)}>
          <i className="fa-solid fa-xmark fa-2xl"></i>
        </button>
      </div>

      <div id="error-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="error-modal-box">
          <img src="images/ban.png" alt="ban" />
          <span className="error-text" id="error-text"></span>
        </div>
      </div>

      <div id="ok-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="ok-modal-box">
          <img src="images/ok.png" alt="ok" />
          <span className="ok-text" id="ok-text"></span>
        </div>
      </div>
    </dialog>
  );
}
