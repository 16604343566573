let errorModal = null;
let okModal = null;

let errorTimeout = -1;
let okTimeout = -1;

export function showError(text, seconds = 10) {
  let errorModal = document.getElementById("error-dialog");
  if (!errorModal) {
    return;
  }

  document.getElementById("error-text").innerText = text;

  clearTimeout(errorTimeout);

  if (okModal) {
    okModal.style.display = 'none';
  }

  errorModal.style.removeProperty('display');
  errorTimeout = setTimeout(() => {
    errorModal.style.display = 'none';
  }, seconds * 1000);
}


export function showOk(text, seconds = 10) {
  let okModal = document.getElementById("ok-dialog");
  if (!okModal) {
    return;
  }

  document.getElementById("ok-text").innerText = text;

  clearTimeout(okTimeout);

  if (errorModal) {
    errorModal.style.display = 'none';
  }

  okModal.style.removeProperty('display');
  okTimeout = setTimeout(() => {
    okModal.style.display = 'none';
  }, seconds * 1000);
}
