export function About() {
  return (
    <section className="about">
      <h2>Добро пожаловать на новый сезон!</h2>

      <div className="about-cards">
        {/* Card */}
        <article className="about-card">
          <img src="./images/about-us.png" alt="О нас" draggable="false" />
          <h3>О нас</h3>
          <span className="about-text">
            KachanovCraft - это уникальный сервер по майнкрафту, который был
            основан 20 мая 2021 года как обычный дружеский сервер для узкого
            круга друзей, но со временем стал быстрорастущим проектом. Здесь
            происходят захватывающие истории, начиная от мировых конфликтов и
            войн, заканчивая тотальной войной между армией тьмы и людьми.
          </span>
        </article>

        {/* Card */}
        {/* <article className="about-card">
          <img
            src="./images/photo-1.png"
            alt="Картинка к факту о нас"
            draggable="false"
          />
          <h3>О нас</h3>
          <span className="about-text">
            Сервер привлекает игроков своими нестандартными методами игрового
            процесса, включая различные системы наказания и постоянно
            обновляемые игровые механики. Здесь каждый игрок может найти свою
            атмосферу. Присоединяйтесь к нашим невероятным приключениям!
          </span>
        </article> */}

        <article className="about-card">
          <img
            src="./images/features.png"
            alt="Особенности игры"
            draggable="false"
          />
          <h3>Особенности игры</h3>
          <span className="about-text">
            Скриптовые унылые ограничители остались позади! Здесь каждому игроку
            предоставляется возможность проявить своё воображение в полной мере.
            Наш сервер уникален тем, что события и развитие игровой вселенной
            зависят исключительно от действий игроков. Здесь вы сможете
            создавать свои уникальные истории и влиять ими на ход игры своими
            действиями.
          </span>
        </article>

        {/* Card */}
        <article className="about-card">
          <img src="./images/wipe.png" alt="Когда вайп?" draggable="false" />
          <h3>Когда вайп?</h3>
          <span className="about-text">
            Ещё одной отличной уникальностью от других проектов является система
            вайпа! Каждый вайп заканчивается логическим завершением (например,
            ядерной войной или биологическим апокалипсисом, созданным игроками),
            а начинается совершенно новым сеттингом и механиками на новой карте!
          </span>
        </article>
      </div>
    </section>
  );
}
