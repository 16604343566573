import { showError, showOk } from "./component-utils/NotificationModals";

export const messages = {
  "login.bad_username": {
    message: "Не удалось авторизоваться с указанными данныи",
    error: true,
  },
  "login.bad_password": {
    message: "Не удалось авторизоваться с указанными данныи",
    error: true,
  },
  "login.unknown_user": {
    message: "Указаны некорректные данные для входа",
    error: true,
  },
  "login.wrong_password": {
    message: "Указаны некорректные данные для входа",
    error: true,
  },

  "register.bad_password": {
    message:
      "Пароль должен состоять из 8-64 английских букв, цифр и -_=+()*&^%$#@!?",
    error: true,
  },
  "register.bad_username": {
    message: "Никнейм должен состоять из 4-16 английских букв, цифр и _",
    error: true,
  },
  "register.bad_email": {
    message: "Указана некорректная поачта",
    error: true,
  },
  "register.email_exists" :{
    message: "Эта почта уже зарегистрирована",
    error: true,
  },
  "register.already_sent" :{
    message: "Письмо уже было отправлено на почту",
    error: true,
  },
  "register.cooldown" :{
    message: "Вы недавно уже делали запрос на регистрацию, попробуйте позднее",
    error: true,
  },
  "register.username_exists" :{
    message: "Этот ник уже зарегистрирован",
    error: true,
  },
  "register.email_was_sent": {
    message: "Проверьте письмо в указанной почте",
    error: false,
  },
  "register.email_was_not_sent": {
    message: "Не получилось отправить письмо, свяжитесь с администрацией",
    error: true,
  },

  "recover_password.bad_email": {
    message: "Указанная некорректная почта!",
    error: true,
  },
  "recover_password.bad_password": {
    message:
      "Пароль должен состоять из 8-64 английских букв, цифр и -_=+()*&^%$#@!?",
    error: true,
  },
  "recover_password.email_was_sent": {
    message: "Проверьте письмо в указанной почте",
    error: false,
  },
  "recover_password.email_was_not_sent": {
    message: "Не получилось отправить письмо, свяжитесь с администрацией",
    error: true,
  },
  "recover_password.already_sent": {
    message: "Письмо уже отправлено на указанную почту!",
    error: true,
  },
};

export function showMessage(message) {
  let data = messages[message];
  if (!data) {
    showError(message);
  } else {
    if (data.error) {
      showError(data.message);
    } else {
      showOk(data.message);
    }
  }
}
