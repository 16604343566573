import { useState } from "react";
import { fetchApiPost } from "../../Requests";
import { RegisterPopUp } from "./Register";
import { showMessage } from "../../messages";

export function RecoverPasswordPopUp({ setDialog }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const recoverPassword = () => {
    if (email.length < 4 || !email.includes('@') || !email.includes('.')) {
      showMessage("recover_password.bad_email");
    } else if (password.length < 8 || password.length > 64) {
      showMessage("recover_password.bad_password");
    } else {
      fetchApiPost("recover_password", {
        email: email,
        password: password,
      }).then(([response, json]) => {
        if (json.ok) {
          showMessage("recover_password.ok");
        } else {
          showMessage(json.message);
        }
      });
    }
  };

  return (
    <dialog id="recover-password-dialog" onClose={() => setDialog(null)}>
      <div className="modal-box" id="login-modal-box">
        <div className="modal-header">
          <h2 id="reg">Восстановить пароль</h2>
          <h2>KachanovCraft</h2>
        </div>
        <div className="modal-columns">
          <input
            type="text"
            placeholder="Почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Новый пароль"
            maxLength="32"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="modal-btn modal-confirm-btn"
            onClick={recoverPassword}
          >
            Восстановить пароль
          </button>
          <span className="modal-under-text" id="no-acc">
            Нет аккаунта?{" "}
            <button
              className="modal-under-text-link"
              id="show-reg-modal-btn2"
              onClick={() => setDialog(<RegisterPopUp setDialog={setDialog} />)}
            >
              Зарегистрируйтесь
            </button>
          </span>
        </div>

        <button className="close-modal-btn" onClick={() => setDialog(null)}>
          <i className="fa-solid fa-xmark fa-2xl"></i>
        </button>
      </div>

      <div id="error-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="error-modal-box">
          <img src="images/ban.png" alt="ban" />
          <span className="error-text" id="error-text"></span>
        </div>
      </div>

      <div id="ok-dialog" style={{ display: "none" }}>
        <div className="modal-box" id="ok-modal-box">
          <img src="images/ok.png" alt="ok" />
          <span className="ok-text" id="ok-text"></span>
        </div>
      </div>
    </dialog>
  );
}
