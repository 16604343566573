import { DownloadPopUp } from "../popups/Download";

export function DownloadButtonNavbar({ setDialog }) {
  return (
    <li>
      <button
        className="button"
        onClick={() => setDialog(<DownloadPopUp setDialog={setDialog} />)}
      >
        Скачать лаунчер
      </button>
    </li>
  );
}
