export function BuyPassButtonNavbar() {
  return (
    <li>
      <a
        className="button"
        href='https://donate.kachanovcraft.com/'
        // onClick={() => window.location = 'https://donate.kachanovcraft.com/'}
      >
        Купить проходку
      </a>
    </li>
  );
}
